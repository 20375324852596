import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ValidationErrors from "./components/shared/validationErrors.vue";
import PlaceHolder from "./components/shared/placeHolder.vue";

// Thirdparty plugins
import VCalendar from "v-calendar";
import Toast from "vue-toastification";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Pagination from "v-pagination-3";

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

// Themes
import "./assets/css/config/default/bootstrap.min.css";
import "./assets/css/config/default/app.min.css";
import "./assets/css/icons.min.css";
import "./app.css";

// Thirdparty plugin's css
import "vue-toastification/dist/index.css";

router.beforeEach((to) => {
   window.scrollTo(0, 0);
   if (to.meta.requiresAuth && !store.getters["auth/isAuth"]) {
      return {
         path: "/login",
      };
   }

   if (to.meta.requiresVisitor && store.getters["auth/isAuth"]) {
      return {
         path: "/admin",
      };
   }

   if (to.meta.requirePermissions) {
      if (
         store.getters["auth/getRoles"].length === 0 ||
         store.getters["auth/getPermissions"].length === 0
      ) {
         const user = {
            name: "",
            id: "",
            roles: [],
            permissions: [],
         };
         store.commit("auth/setAuthUser", user);
         store.commit("auth/setToken", "");
         store.commit("auth/setAuth", false);

         return {
            path: "/login",
         };
      }

      const permissionArray = to.meta.permissions.filter((permission) =>
         store.getters["auth/getPermissions"].includes(permission)
      );

      const isSuperAdmin = store.getters["auth/getRoles"].includes(
         "superadmin"
      );

      if (permissionArray.length === 0 && !isSuperAdmin) {
         return {
            path: "/page-not-found",
         };
      }
   }
});

const app = createApp(App);

app.use(router);
app.use(store);

// plugins
app.use(VCalendar, {});
app.use(Toast, {
   transition: "my-custom-fade",
   maxToasts: 20,
   newestOnTop: true,
   position: "top-right",
   timeout: 3000,
   closeOnClick: true,
   pauseOnFocusLoss: true,
   pauseOnHover: true,
   draggable: true,
   draggablePercent: 0.6,
   showCloseButtonOnHover: false,
   closeButton: "button",
   icon: true,
   rtl: false,
});
app.use(VueProgressBar, {
   color: "rgb(143, 255, 199)",
   failedColor: "red",
});
app.component("pagination", Pagination);
app.component("place-holder", PlaceHolder);

//Custom components
app.component("v-errors", ValidationErrors);

app.mount("#app");
