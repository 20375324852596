const adminRoutes = [
   {
      path: "users",
      name: "user",
      component: () => import("../../pages/user/User.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["user-list"],
      },
   },
   {
      path: "users/create",
      name: "user-create",
      component: () => import("../../pages/user/Form.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["user-create"],
      },
   },
   {
      path: "users/:id/edit",
      name: "user-update",
      component: () => import("../../pages/user/Form.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["user-edit"],
      },
   },
   {
      path: "roles",
      name: "roles",
      component: () => import("../../pages/role/Role.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["role-list"],
      },
   },
   {
      path: "roles/create",
      name: "role-create",
      component: () => import("../../pages/role/Form.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["role-create"],
      },
   },
   {
      path: "roles/:id/edit",
      name: "role-update",
      component: () => import("../../pages/role/Form.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["role-edit"],
      },
   },
   {
      path: "permissions",
      name: "permissions",
      component: () => import("../../pages/permission/Permission.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["permission-list"],
      },
   },
   {
      path: "permissions/create",
      name: "permission-create",
      component: () => import("../../pages/permission/Form.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["permission-create"],
      },
   },
   {
      path: "permissions/:id/edit",
      name: "permission-update",
      component: () => import("../../pages/permission/Form.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["permission-edit"],
      },
   },
   {
      path: "rooms",
      name: "rooms",
      component: () => import("../../pages/room/Index.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["room-list"],
      },
   },
   {
      path: "meetings",
      name: "meetings",
      component: () => import("../../pages/meeting/Meeting.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["meeting-list"],
      },
   },
   {
      path: "meetings/:id/edit",
      name: "meeting-update",
      component: () => import("../../pages/meeting/Edit.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["meeting-edit"],
      },
   },
   {
      path: "meeting-create",
      name: "meeting-create",
      component: () => import("../../pages/meeting/Create.vue"),
      meta: {
         requirePermissions: true,
         permissions: ["meeting-create"],
      },
   },
];

export default adminRoutes;
