const authRoutes = [
   {
      path: "/login",
      name: "login",
      component: () => import("../../pages/auth/Login.vue"),
      meta: { requiresVisitor: true },
   },
   {
      path: "/:pathMatch(.*)*",
      name: "PageNotFound",
      component: () => import("../../pages/PageNotFound.vue"),
   },
];

export default authRoutes;
