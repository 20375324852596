import axios from "axios";
import store from "../store";
import router from "../router";

const baseUrl = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(
   function(config) {
      config.headers.common["Authorization"] = store.getters["auth/getToken"];
      config.headers.common["Content-Type"] = "application/json";
      return config;
   },
   function(error) {
      return Promise.reject(error);
   }
);

axios.interceptors.response.use(
   (response) => {
      return response;
   },
   (error) => {
      if (401 === error.response.status) {
         const user = {
            name: "",
            id: "",
            roles: [],
            permissions: [],
         };
         store.commit("auth/setAuthUser", user);
         store.commit("auth/setToken", "");
         store.commit("auth/setAuth", false);

         router.push({ name: "login" });
      }
      return Promise.reject(error);
   }
);

export const HTTP = axios;
