import { createWebHistory, createRouter } from "vue-router";
import adminRoutes from "./admin";
import authRoutes from "./auth";

const baseRoutes = [
   {
      path: "/admin",
      name: "admin-home",
      component: () => import("../components/layouts/Master.vue"),
      meta: {
         requiresAuth: true,
         requirePermissions: true,
         permissions: [
            "user-list",
            "user-create",
            "user-edit",
            "room-list",
            "meeting-list",
            "meeting-edit",
            "meeting-create",
            "role-list",
            "role-create",
            "role-edit",
            "permission-list",
            "permission-create",
            "permission-edit",
         ],
      },
      children: adminRoutes,
   },
   {
      path: "/",
      name: "home",
      component: () => import("../pages/meeting/MeetingCalendar.vue"),
      meta: {
         requiresAuth: true,
         requirePermissions: true,
         permissions: ["meeting-list"],
      },
   },
];

const routes = baseRoutes.concat(authRoutes);

const router = createRouter({
   history: createWebHistory(),
   routes,
});

export default router;
