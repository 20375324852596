import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";

const store = createStore({
   modules: {
      auth,
   },
   state() {
      return {
         momTemplateFileUrl: process.env.VUE_APP_MOM_TEMPLATE_FILE_URL,
      };
   },
   getters: {
      getMomTemplateFileUrl(state) {
         return state.momTemplateFileUrl;
      },
   },
   actions: {},
   mutations: {},
   plugins: [createPersistedState()],
});

export default store;
